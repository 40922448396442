export const topmenu = {
  account: "Kundkonto",
  homes: "Avdelning",
  users: "Medarbetare",
  staff: "Personal",
  overview: "Överblick",
  statistics: "Statistik",
  calendar: "Kalender",

  access_level_super_admin: "Super",
  access_level_account_admin: "Behörighet 1",
  access_level_operations_manager: "Behörighet 2",
  access_level_home_manager: "Behörighet 3",
  logged_in_with: "Inloggad med ",
};
