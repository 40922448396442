import {
  PERCENT_RED,
  PERCENT_YELLOW,
  WELLBEING_SAD,
  WELLBEING_HAPPY,
  WELLBEING_NEUTRAL,
} from "appConfig";
import {
  Stats,
  HomeStats,
  StatsAndMergedSummary,
  UserStats,
  ActivityStats,
  ObjectiveStatsAndMergedSummary,
  MonthlyStatsAndSummary,
  StaffSignOffStats,
  Staff,
  StaffMap,
  Activity,
  ActivityMap,
  Wellbeing,
  WellbeingSummary,
  newWellbeingSummary,
} from "model/stats";
import { unixtime2JSDate } from "./date_utils";

const newStats = (): Stats => {
  return {
    count_total: 0,
    count_performed: 0,
    count_unable_to_perform: 0,
    percent_performed: 0,
  };
};

const newMergedSummary = (
  stats: MonthlyStatsAndSummary[]
): StatsAndMergedSummary => {
  const merged: StatsAndMergedSummary = {
    stats: [],
    months: [],
    summary: newStats(),
  };
  if (!stats) return merged;
  merged.stats = stats;
  for (let i = 0; i < 12; i++) merged.months.push(newStats());
  return merged;
};

export const getStatus = (
  percent: number,
  red: any,
  yellow: any,
  green: any
) => {
  return percent < PERCENT_RED
    ? red
    : percent < PERCENT_YELLOW
    ? yellow
    : green;
};

const summarizeItem = (
  item: MonthlyStatsAndSummary,
  summary: Stats,
  months: Stats[]
) => {
  if (!item.summary) item.summary = newStats();

  for (let i = 0; i < item.months.length; i++) {
    const month = item.months[i];
    if (!month.count_total) continue;

    month.percent_performed = Math.round(
      (month.count_performed * 100) / month.count_total
    );

    item.summary.count_total += month.count_total;
    item.summary.count_performed += month.count_performed;
    item.summary.count_unable_to_perform += month.count_unable_to_perform;

    summary.count_total += month.count_total;
    summary.count_performed += month.count_performed;
    summary.count_unable_to_perform += month.count_unable_to_perform;

    months[i].count_total += month.count_total;
    months[i].count_performed += month.count_performed;
    months[i].count_unable_to_perform += month.count_unable_to_perform;
  }

  if (item.summary.count_total)
    item.summary.percent_performed = Math.round(
      (item.summary.count_performed * 100) / item.summary.count_total
    );
};

const summarizePercents = (summary: Stats, months: Stats[]) => {
  for (let i = 0; i < 12; i++) {
    const m = months[i];
    if (!m.count_total) continue;
    m.percent_performed = Math.round((m.count_performed * 100) / m.count_total);
  }

  if (summary.count_total)
    summary.percent_performed = Math.round(
      (summary.count_performed * 100) / summary.count_total
    );
};

export const processMonthlyStats = (
  stats: MonthlyStatsAndSummary[]
): StatsAndMergedSummary => {
  const merged: StatsAndMergedSummary = newMergedSummary(stats);
  if (!stats) return merged;

  for (const item of stats) {
    summarizeItem(item, merged.summary, merged.months);
  }

  summarizePercents(merged.summary, merged.months);

  return merged;
};

export const processStaffMonthlyStats = (
  stats: MonthlyStatsAndSummary[]
): [StatsAndMergedSummary, StaffMap] => {
  const merged: StatsAndMergedSummary = processMonthlyStats(stats);
  if (!stats) return [merged, {}];

  const staffmap: StaffMap = {};

  for (const item of merged.stats) {
    const staff = item as StaffSignOffStats;
    staff.active_days_total = 0;

    for (const ad of staff.active_days) {
      staff.active_days_total += ad;
    }
    const staffobj: Staff = {
      uuid: staff.staff_uuid,
      identifier: staff.staff_identifier,
    };
    staffmap[staffobj.uuid] = staffobj;
  }
  return [merged, staffmap];
};

export const processActivityStats = (
  stats: ActivityStats[]
): [ObjectiveStatsAndMergedSummary[], ActivityMap] => {
  const list: ObjectiveStatsAndMergedSummary[] = [];
  const map: { [id: number]: ObjectiveStatsAndMergedSummary } = {};
  const activitymap: ActivityMap = {};

  for (const item of stats) {
    let obj: ObjectiveStatsAndMergedSummary;
    if (item.objective in map) {
      obj = map[item.objective];
    } else {
      obj = {
        id: item.objective,
        title: item.objective_title,
        color: item.objective_color,
        months: [],
        summary: newStats(),
        stats: [],
      };
      for (let i = 0; i < 12; i++) obj.months.push(newStats());
      list.push(obj);
      map[obj.id] = obj;
    }
    obj.stats.push(item);

    if (!(item.activity in activitymap)) {
      const act: Activity = {
        id: item.activity,
        title: item.activity_title,
        objective: item.objective,
        objective_title: item.objective_title,
        objective_color: item.objective_color,
      };
      activitymap[act.id] = act;
    }

    summarizeItem(item, obj.summary, obj.months);
  }

  for (const obj of list) {
    summarizePercents(obj.summary, obj.months);
  }

  return [list, activitymap];
};

export const getObjectiveColor = (color: string) => {
  if (!color) return "inherit";
  if (color.startsWith("#FF") && color.length == 9)
    return "#" + color.substring(3);
  return color;
};

export const incrementWellbeingSummary = (
  summary: WellbeingSummary,
  wellbeing: Wellbeing
) => {
  summary.total++;
  if (wellbeing.wellbeing === WELLBEING_HAPPY) {
    summary.happy++;
  } else if (wellbeing.wellbeing === WELLBEING_NEUTRAL) {
    summary.neutral++;
  } else if (wellbeing.wellbeing === WELLBEING_SAD) {
    summary.sad++;
  }
};

export const processUserWellbeing = (
  wellbeing: Wellbeing[]
): [Wellbeing[][][], WellbeingSummary] => {
  const calendar: Wellbeing[][][] = [];
  for (let m = 0; m < 12; m++) {
    calendar.push([]); //month 0-11
    const month = calendar[m];
    for (let d = 0; d < 31; d++) {
      month.push([]); //day 0-30
    }
  }
  const summary: WellbeingSummary = newWellbeingSummary();

  for (const w of wellbeing) {
    const date = unixtime2JSDate(w.unix_timestamp);
    const month = date.getMonth();
    const day = date.getDate();
    calendar[month][day].push(w);
    incrementWellbeingSummary(summary, w);
  }

  return [calendar, summary];
};
