import React, { useState, useRef, useEffect } from "react";

import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";

import { getLanguageString as _ } from "misc/lang";
import { getLanguageStringArray as _ar } from "misc/lang";
import { classNames } from "misc/helpers";

import { DefaultProps } from "model/props";
import {
  apiGetAccountStats,
  apiGetHomeStats,
  apiGetUserStats,
  apiGetStaffStatsHome,
  apiGetStaffStatsUser,
  apiGetUserCalendarHistory,
  apiGetWellbeingHistory,
} from "net/requests";

import cssLayout from "../layout.module.scss";
import css from "./statsscreen.module.scss";

import {
  processActivityStats,
  getStatus,
  processMonthlyStats,
  processStaffMonthlyStats,
  getObjectiveColor,
  processUserWellbeing,
} from "misc/stats_util";
import TwoGraph from "components/stats/TwoGraph";
import BoxPercent from "components/stats/BoxPercent";
import BoxSignOff from "components/stats/BoxSignOff";

import {
  Stats,
  ActivityStats,
  HomeStats,
  ObjectiveStatsAndMergedSummary,
  StatsAndMergedSummary,
  MonthlyStatsAndSummary,
  UserStats,
  StaffSignOffStats,
  StaffMap,
  ActivityMap,
  Wellbeing as WellbeingData,
  WellbeingSummary,
} from "model/stats";
import { CalendarActivity } from "model/calendar";
import FormButton from "components/form/FormButton";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { COLOR_PALETTE, COLOR_GRAY } from "appConfig";
import ActivityTable from "components/stats/ActivityTable";
import { FAKE_WELLBEING } from "net/fake_respons";
import WellbeingCalendar from "components/wellbeing/WellbeingCalendar";
import Wellbeing from "components/wellbeing/Wellbeing";

const StatsScreen: React.FC<DefaultProps> = (props) => {
  const { admin, account, stats, setStats, loader } = props;

  ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    BarElement
  );

  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [period, setPeriod] = useState<number>(new Date().getMonth());
  const [homeStats, setHomeStats] = useState<StatsAndMergedSummary>();
  const [staffStatsHome, setStaffStatsHome] = useState<StatsAndMergedSummary>();
  const [staffMap, setStaffMap] = useState<StaffMap>();
  const [staffStatsUser, setStaffStatsUser] = useState<StatsAndMergedSummary>();
  const [selectedHome, setSelectedHome] = useState<HomeStats>();
  const [selectedUser, setSelectedUser] = useState<UserStats>();
  const [userWellbeing, setUserWellbeing] = useState<WellbeingData[][][]>();
  const [userWellbeingYearSummary, setUserWellbeingYearSummary] =
    useState<WellbeingSummary>();

  const [objectives, setObjectives] =
    useState<ObjectiveStatsAndMergedSummary[]>();
  const [activityMap, setActivityMap] = useState<ActivityMap>();
  const [selectedObjective, setSelectedObjective] =
    useState<ObjectiveStatsAndMergedSummary>();
  const [lineChartData, setLineChartData] = useState<any>();
  const [barChartData, setBarChartData] = useState<any>();
  const [userCalendarHistory, setUserCalendarHistory] =
    useState<CalendarActivity[][]>();

  const ref = useRef();

  /////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setPeriod(-1);
    setSelectedUser(undefined);

    // eslint-disable-next-line
  }, [year]);

  useEffect(() => {
    actionLoadAccountStats();

    // eslint-disable-next-line
  }, [account, year]);

  useEffect(() => {
    setHomeStats(undefined);
    if (selectedHome?.home?.uuid) actionLoadHomeStats(selectedHome.home.uuid);

    // eslint-disable-next-line
  }, [selectedHome, year]);

  useEffect(() => {
    setObjectives(undefined);
    setSelectedObjective(undefined);
    setUserWellbeing(undefined);
    if (selectedUser?.user?.uuid)
      actionLoadUserStats(
        selectedUser.user.uuid,
        selectedHome?.home.uuid || ""
      );

    // eslint-disable-next-line
  }, [selectedUser, year]);

  useEffect(() => {
    if (!selectedObjective) return;

    const datasets = [];

    if (period < 0) {
      // FULL YEAR

      // BARS
      for (let i = 0; i < selectedObjective.stats.length; i++) {
        const item = selectedObjective.stats[i];
        datasets.push({
          data: item.months.map((m) => m.count_performed),
          //fill: false,
          //tension: 0.1,
          backgroundColor: COLOR_PALETTE[i],
          stack: "stack-" + i,
        });
        datasets.push({
          data: item.months.map((m) => m.count_total - m.count_performed),
          //fill: false,
          //tension: 0.1,
          backgroundColor: COLOR_GRAY,
          stack: "stack-" + i,
        });
      }
      const data = {
        labels: _ar("general", "month_names"),
        datasets: datasets,
      };
      setBarChartData(data);
    } else {
      // BAR
      const labels: string[] = [];
      const colors: string[] = [];

      const data_performed = [];
      const data_total = [];

      for (let i = 0; i < selectedObjective.stats.length; i++) {
        const item = selectedObjective.stats[i] as ActivityStats;
        labels.push(item.activity_title);
        colors.push(COLOR_PALETTE[i]);
        const m = item.months[period];
        data_performed.push(m.count_performed);
        data_total.push(m.count_total - m.count_performed);
      }

      datasets.push({
        data: data_performed,
        backgroundColor: colors,
        stack: "stack0",
      });
      datasets.push({
        data: data_total,
        backgroundColor: COLOR_GRAY,
        stack: "stack0",
      });

      const data = {
        labels: labels,
        datasets: datasets,
      };
      setBarChartData(data);
    }

    // eslint-disable-next-line
  }, [selectedObjective, period]);

  /////////////////////////////////////////////////////////////////////////

  const actionLoadAccountStats = () => {
    if (!account) return;
    loader.setLoading();

    apiGetAccountStats(admin, account.uuid, year)
      .then((response) => {
        loader.reset();
        const summary = processMonthlyStats(response.data);
        setStats(summary);

        const notSingle = summary.stats.length !== 1;
        if (notSingle) {
          setSelectedHome(undefined);
        } else {
          setSelectedHome(summary.stats[0] as HomeStats);
        }
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  const actionLoadHomeStats = (home_uuid: string) => {
    if (!account) return;
    loader.setLoading(2);

    apiGetHomeStats(admin, home_uuid, year)
      .then((response) => {
        loader.resetOnZero();
        const merged = processMonthlyStats(response.data);
        setHomeStats(merged);
      })
      .catch((error) => {
        loader.resetOnZero();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });

    apiGetStaffStatsHome(admin, home_uuid, year)
      .then((response) => {
        loader.resetOnZero();
        const [merged, staffmap] = processStaffMonthlyStats(response.data);
        setStaffStatsHome(merged);
        setStaffMap(staffmap);
      })
      .catch((error) => {
        loader.resetOnZero();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  const actionLoadUserStats = (user_uuid: string, home_uuid: string) => {
    if (!account) return;
    loader.setLoading(3);

    apiGetUserStats(admin, user_uuid, year)
      .then((response) => {
        loader.resetOnZero();
        const [objectives, activitymap] = processActivityStats(response.data);
        setObjectives(objectives);
        setActivityMap(activitymap);
      })
      .catch((error) => {
        loader.resetOnZero();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });

    apiGetStaffStatsUser(admin, home_uuid, user_uuid, year)
      .then((response) => {
        loader.resetOnZero();
        const [merged] = processStaffMonthlyStats(response.data); //skipping staffmap
        setStaffStatsUser(merged);
      })
      .catch((error) => {
        loader.resetOnZero();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });

    apiGetUserCalendarHistory(admin, user_uuid, year)
      .then((response) => {
        loader.resetOnZero();
        setUserCalendarHistory(response.data);
      })
      .catch((error) => {
        loader.resetOnZero();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });

    apiGetWellbeingHistory(admin, user_uuid, year)
      .then((response) => {
        loader.resetOnZero();
        const [calendar, summary] = processUserWellbeing(response.data);
        setUserWellbeing(calendar);
        setUserWellbeingYearSummary(summary);
      })
      .catch((error) => {
        loader.resetOnZero();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////
  if (!stats) return <></>;

  const month_name = _ar("general", "month_names");
  const multipleHomes = stats.stats.length > 1;

  const periodString =
    period < 0 ? _("stats", "whole_year") : month_name[period];

  const periodSummary = period < 0 ? stats.summary : stats.months[period];

  const periodHome =
    homeStats && (period < 0 ? homeStats.summary : homeStats.months);

  const periodUser =
    selectedUser &&
    (period < 0 ? selectedUser.summary : selectedUser.months[period]);

  let legendIndex = -1;

  return (
    <div className={cssLayout.screen}>
      <div className={css.stats}>
        {/* HEADER */}
        <div className={css.mainHeading}>
          <div className={classNames(cssLayout.pageHeading, css.title)}>
            {_("stats", "main_title")}
          </div>
          <div className={css.dropdownWrapper}>
            <FormControl className={css.dropdown}>
              <InputLabel id="demo-simple-select-label">
                {_("stats", "year")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label={_("stats", "year")}
                onChange={(event) => setYear(event.target.value as number)}
                size="small"
              >
                {[2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030].map(
                  (y) => {
                    if (y > new Date().getFullYear()) return undefined;

                    return (
                      <MenuItem key={y} value={y}>
                        {y}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </div>
          <div className={css.dropdownWrapper}>
            <FormControl className={css.dropdown}>
              <InputLabel id="demo-simple-select-label">
                {_("stats", "period")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={period}
                label={_("stats", "period")}
                onChange={(event) => setPeriod(event.target.value as number)}
                size="small"
              >
                <MenuItem key={-1} value={-1}>
                  {_("stats", "whole_year")}
                </MenuItem>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((m) => {
                  if (stats.months[m].count_total > 0) {
                    return (
                      <MenuItem key={m} value={m}>
                        {month_name[m]}
                      </MenuItem>
                    );
                  }
                  return undefined;
                })}
              </Select>
            </FormControl>
          </div>
        </div>

        {/* ALT 1 - COMBINED ACCOUNT STATS */}
        {!selectedHome && multipleHomes && (
          <>
            <div className={cssLayout.pageHeading}>
              {(account?.name || "") +
                ((!(admin.is_super_admin || admin.is_account_admin) &&
                  " - " + _("stats", "my_homes")) ||
                  "")}
            </div>
            <div className={css.overview}>
              <TwoGraph
                period={periodString}
                summary={periodSummary}
                months={stats.months}
              />
            </div>

            <div className={cssLayout.pageHeading}>
              {((admin.is_super_admin || admin.is_account_admin) &&
                _("stats", "homes")) ||
                _("stats", "my_homes")}
            </div>
            <div className={css.boxes}>
              {stats.stats.map((item) => {
                const home = item as HomeStats;
                return (
                  <BoxPercent
                    id={"home_" + home.home.uuid}
                    name={home.home.name}
                    item={home}
                    period={period}
                    fnClick={(event) => setSelectedHome(home)}
                  />
                );
              })}
            </div>
          </>
        )}

        {/* ALT 2 - SELECTED HOME / USER STATS */}
        {selectedHome && periodHome && (
          <>
            {/* STATE 1 - WHEN NO USER IS SELECTED */}
            {!selectedUser && (
              <>
                <div className={cssLayout.pageHeading}>
                  {multipleHomes && (
                    <div className={css.btnBack}>
                      <FormButton
                        onSubmit={(event) => setSelectedHome(undefined)}
                        variant="outlined"
                        color="inherit"
                        label={_("general", "btn_back")}
                        size="small"
                        inline={true}
                      />
                    </div>
                  )}
                  {selectedHome?.home.name || ""}
                </div>
                <div className={css.overview}>
                  <TwoGraph
                    period={periodString}
                    summary={periodHome as Stats}
                    months={selectedHome.months}
                  />
                </div>

                {/* RENDER STATS FOR ALL USERS IN HOME */}
                {homeStats && (
                  <>
                    <div className={cssLayout.pageHeading}>
                      {_("stats", "users")}
                    </div>
                    <div className={css.boxes}>
                      {homeStats.stats.map((item) => {
                        const user = item as UserStats;

                        return (
                          <BoxPercent
                            id={"usr_" + user.user.uuid}
                            name={user.user.identifier}
                            item={user}
                            period={period}
                            fnClick={(event) => setSelectedUser(user)}
                          />
                        );
                      })}
                    </div>
                  </>
                )}

                {/* RENDER STATS FOR ALL STAFF IN HOME */}
                {/* {staffStatsHome && (
                  <>
                    <div className={cssLayout.pageHeading}>{_("stats", "staff")}</div>
                    <div className={css.boxes}>
                      {staffStatsHome.stats.map((item) => {
                        const staff = item as StaffSignOffStats;
                        return <BoxSignOff staff={staff} period={period} />;
                      })}
                    </div>
                  </>
                )} */}
              </>
            )}

            {/* STATE 2 - IF WE HAVE SELECTED AN USER */}
            {selectedUser && (
              <>
                {periodUser && (
                  <>
                    <div className={cssLayout.pageHeading}>
                      <div className={css.btnBack}>
                        <FormButton
                          onSubmit={(event) => setSelectedUser(undefined)}
                          variant="outlined"
                          color="inherit"
                          label={_("general", "btn_back")}
                          size="small"
                          inline={true}
                        />
                      </div>
                      {selectedUser.user.identifier}
                    </div>
                    <TwoGraph
                      period={periodString}
                      summary={periodUser}
                      months={selectedUser.months}
                    />
                  </>
                )}

                {userWellbeing && userWellbeingYearSummary && (
                  <>
                    <div className={cssLayout.pageHeading}>
                      {_("stats", "wellbeing")}
                    </div>
                    <Wellbeing
                      {...props}
                      year={year}
                      month={period}
                      data={userWellbeing}
                      yearSummary={userWellbeingYearSummary}
                    />
                  </>
                )}

                {/* RENDER STATS FOR ALL STAFF IN HOME */}
                {staffStatsUser && (
                  <>
                    <div className={cssLayout.pageHeading}>
                      {_("stats", "staff")}
                    </div>
                    <div className={css.boxes}>
                      {staffStatsUser.stats.map((item) => {
                        const staff = item as StaffSignOffStats;
                        return <BoxSignOff staff={staff} period={period} />;
                      })}
                    </div>
                  </>
                )}

                {/* SHOW OBJECTIVES SUMMARY */}
                {objectives && (
                  <>
                    <div className={cssLayout.pageHeading}>
                      {_("stats", "objectives")}
                    </div>
                    <div className={css.boxes}>
                      {objectives.map((obj) => {
                        return (
                          <BoxPercent
                            id={"obj_" + obj.id}
                            name={obj.title}
                            item={obj}
                            period={period}
                            fnClick={(event) => setSelectedObjective(obj)}
                            style={{
                              backgroundColor: getObjectiveColor(obj.color),
                            }}
                          />
                        );
                      })}
                    </div>
                  </>
                )}

                {/* SHOW ACTIVITES FOR SELECTED OBJECTIVE */}
                {selectedObjective && (
                  <>
                    <div className={cssLayout.pageHeading}>
                      <div className={css.btnBack}>
                        <FormButton
                          onSubmit={(event) => setSelectedObjective(undefined)}
                          variant="outlined"
                          color="inherit"
                          label={_("general", "btn_close")}
                          size="small"
                          inline={true}
                        />
                      </div>
                      {selectedObjective.title}
                    </div>
                    {barChartData && (
                      <div className={css.chartContainer}>
                        <div className={css.chart}>
                          <Bar
                            ref={ref}
                            data={barChartData}
                            options={barChartOptions}
                          />
                        </div>
                      </div>
                    )}

                    <div className={css.legendContainer}>
                      {selectedObjective.stats.map((t) => {
                        const item = t as ActivityStats;
                        const count =
                          period < 0
                            ? item.summary.count_total
                            : item.months[period].count_total;

                        if (typeof count === "undefined") return <></>;
                        legendIndex++;

                        return (
                          <div
                            key={"legend-" + legendIndex}
                            className={css.legend}
                          >
                            <div
                              className={css.dot}
                              style={{
                                backgroundColor: COLOR_PALETTE[legendIndex],
                              }}
                            ></div>
                            <div className={css.text}>
                              {item.activity_title}
                            </div>
                            <div className={css.count}>{count}x</div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}

                {/* SHOW ACTIVITES LIST (CALENDAR) (FOR SELECTED) OBJECTIVE) */}
                {userCalendarHistory && (
                  <>
                    <div className={cssLayout.pageHeading}>
                      {_("stats", "activites")}
                      {(selectedObjective &&
                        " (" + selectedObjective.title + ")") ||
                        ""}
                    </div>
                    <ActivityTable
                      staffMap={staffMap}
                      activityMap={activityMap}
                      period={period}
                      data={userCalendarHistory}
                      objectiveId={
                        (selectedObjective && selectedObjective.id) || undefined
                      }
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const lineChartOptions = {
  scales: {
    y: {
      min: 0,
      ticks: {
        stepSize: 1,
      },
    },
  },
  maintainAspectRatio: false,
  animation: { duration: 0 },
};

const barChartOptions = {
  scales: {
    x: {
      stacked: true,
    },
    y: {
      min: 0,
      ticks: {
        stepSize: 1,
      },
      stacked: true,
    },
  },
  maxBarThickness: 40,
  maintainAspectRatio: false,
  animation: { duration: 0 },
};

export default StatsScreen;
