export const stats = {
  main_title: "Statistik",
  performed_activites: "utförda aktiviteter",
  total_activities: "schemalagda aktiviteter",
  whole_year: "Helår",
  period: "Period",
  year: "År",
  my_homes: "Mina boenden",
  homes: "Avdelning",
  users: "Medarbetare",
  objectives: "Målsättningar",
  x_times: " gånger",
  staff: "Personal (avcheckning)",
  per_day: "/dag",
  per_day_template: "{1} aktiviteter avcheckade på {2} dagar = {3} per / dag",
  day: "dag",
  days: "dagar",
  box_performed: "Utförda",
  box_unable: "Ej möjligt",
  box_not_performed: "Ej utförda",
  performed: "Utförd",
  unable: "Ej möjligt",
  not_performed: "Ej utförd",
  activites: "Aktiviteter",
  wellbeing: "Mående",
};
